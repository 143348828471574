export class EventTrackerModule {
  pixel

  constructor(pixel) {
    this.pixel = pixel
  }

  track(name, params) {
    const trustedFormToken = document.getElementsByName("xxTrustedFormToken")[0]?.value
    const leadidToken =
      typeof LeadiD != "undefined" && LeadiD.token && typeof LeadiD.token != "undefined" && LeadiD.token
    let referrer = ""
    let referrerParams = {}
    const currentParams = window.location.search
    if (document.body.dataset.hiddenReferrer) {
      referrer = new URL(document.body.dataset.hiddenReferrer)
      referrerParams = Object.fromEntries(new URLSearchParams(referrer.search))
    }

    const additionalParams = {
      userAgent: navigator.userAgent,
      urlParams: currentParams,
      trusted_form_token: trustedFormToken,
      leadid_token: leadidToken,
      ls_questions_results: window.localStorage.getItem("questions_results"),
      ls_saved_user: window.localStorage.getItem("savedUser"),
      hidden_referrer: referrer,
      hidden_referer_params: referrerParams,
    }
    this.pixel.track(name, { params: params, additionalParams: additionalParams })
  }
}
